import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "container templates" }
const _hoisted_2 = { class: "d-flex w-100 justify-content-end page-heading-toolbar" }
const _hoisted_3 = { class: "page-heading-toolbar--col" }
const _hoisted_4 = { class: "page-heading-toolbar--col" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ManageTemplateModal = _resolveComponent("ManageTemplateModal")!
  const _component_ShadowControl = _resolveComponent("ShadowControl")!
  const _component_BtnContent = _resolveComponent("BtnContent")!
  const _component_AppButton = _resolveComponent("AppButton")!
  const _component_PageMainHeading = _resolveComponent("PageMainHeading")!
  const _component_TemplatesTable = _resolveComponent("TemplatesTable")!
  const _component_AppTableController = _resolveComponent("AppTableController")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_ManageTemplateModal, {
      id: _ctx.editingModalData?.id,
      data: _ctx.editingModalData.id != 0 ? _ctx.editingModalData : null,
      opened: _ctx.manageTemplateModalOpened,
      "is-open": _ctx.manageTemplateModalOpened,
      loading: _ctx.modalLoading,
      types: _ctx.templatesTypesList,
      onHide: _cache[0] || (_cache[0] = ($event: any) => (_ctx.toggleManageTemplateModal({ status: false }))),
      onSubmit: _ctx.endEditingTemplate,
      onRemove: _ctx.deleteTemplate
    }, null, 8, ["id", "data", "opened", "is-open", "loading", "types", "onSubmit", "onRemove"]),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_PageMainHeading, { title: "Templates" }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createVNode(_component_ShadowControl, {
                id: "templates-search",
                class: "search-control",
                icon: "search",
                placeholder: "Search...",
                name: "accounts-search",
                "input-timeout": true,
                onChange: _ctx.searchHandler
              }, null, 8, ["onChange"])
            ]),
            _createElementVNode("div", _hoisted_4, [
              _createVNode(_component_AppButton, {
                type: "secondary",
                size: "lg"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_BtnContent, {
                    label: "Create Template",
                    icon: "plus",
                    onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.toggleManageTemplateModal({ status: true })))
                  })
                ]),
                _: 1
              })
            ])
          ])
        ]),
        _: 1
      })
    ]),
    _createVNode(_component_AppTableController, {
      "show-table": !!_ctx.templatesList.length,
      "search-query": _ctx.searchQuery,
      "empty-button-text": "Create Template",
      "empty-key": "templates",
      "empty-icon": "templates",
      onEmptyButtonClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.toggleManageTemplateModal({ status: true })))
    }, {
      default: _withCtx(() => [
        (_ctx.templatesList.length)
          ? (_openBlock(), _createBlock(_component_TemplatesTable, {
              key: 0,
              "templates-list": _ctx.templatesList,
              "total-rows-count": _ctx.totalTemplatesCount,
              "view-configuration": _ctx.viewConfiguration,
              sort: _ctx.sortConfig,
              onToggleEdit: _ctx.toggleManageTemplateModal,
              onDelete: _ctx.deleteTemplate,
              onChangeViewConfiguration: _ctx.changeViewConfigurationHandler
            }, null, 8, ["templates-list", "total-rows-count", "view-configuration", "sort", "onToggleEdit", "onDelete", "onChangeViewConfiguration"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["show-table", "search-query"])
  ], 64))
}