
import Dialog from "@/components/ui/Modal/Dialog/Dialog.vue";
import AppInput from "@/components/Form/AppInput.vue";
import SelectInput from "@/components/Form/SelectInput.vue";
import AppButton from "@/components/ui/Buttons/AppButton.vue";
import AppCheckbox from "@/components/Form/AppCheckbox.vue";
import { defineComponent } from "vue";
const defaultTitle = "New Template";
interface submitData {
  html: string;
  subject: string;
  isActive: boolean;
}

interface selectedType {
  id: number;
  active: boolean;
  name: string;
  selected: boolean;
}

export default defineComponent({
  name: "ManageTemplateModal",
  components: { AppCheckbox, AppButton, AppInput, SelectInput, Dialog },
  props: {
    data: {
      type: Object,
      default: () => {
        return {
          code: "",
          id: 0,
          subject: "",
          status: false,
          typeId: 0
        };
      }
    },
    types: {
      type: Object,
      default: () => {
        return {
          id: 0,
          active: true,
          name: "",
          selected: false
        } as selectedType;
      }
    },
    isOpen: { type: Boolean, default: false },
    loading: { type: Boolean, default: false }
  },
  emits: ["submit", "remove"],
  data() {
    return {
      submitData: {} as submitData
    };
  },

  computed: {
    isEdit(): string {
      if (this.data) return this.data.subject;
      return defaultTitle;
    },
    modalTitle(): string {
      return this.data?.subject ?? defaultTitle;
    },
    typeSelect(): string {
      const titleFind: selectedType = this.types.find(
        (type: selectedType) => type.selected
      );
      if (titleFind?.name) return titleFind.name;
      const title: string = this.types.find(
        (type: selectedType) => type.id === this.data?.typeId
      )?.name;
      if (title) return title;
      return "";
    }
  },

  watch: {
    isOpen(): void {
      if (this.data?.id) {
        this.submitData = {
          subject: this.data.subject,
          html: this.data.code,
          isActive: this.data.status
        };
      } else {
        this.submitData = {
          isActive: false
        } as submitData;
      }
    }
  },
  methods: {
    remove(): void {
      this.$emit("remove", this.data?.id);
    },
    submit(): void {
      this.$emit("submit", {
        ...this.submitData,
        typeId:
          this.types.find((type: selectedType) => type.selected)?.id ??
          this.data.typeId
      });
      this.submitData = {} as submitData;
    },
    getDefaultHtml(): string {
      if (!this.data) {
        return "";
      }
      return this.data.code;
    },
    typesSelectHandler(id: number): void {
      this.types.forEach((f: selectedType) => (f.selected = f.id === id));
    }
  }
});
