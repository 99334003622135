export const templateTableHead = [
  {
    size: "xl",
    sort: "subject",
    label: "subject",
    id: "subject"
  },
  {
    size: "lg",
    sort: "data-created",
    label: "data created"
  },
  {
    size: "lg",
    sort: "created-by",
    label: "created by"
  },
  {
    size: "md",
    label: "status"
  },
  {
    size: "xxs",
    label: ""
  }
];
