import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "account-wrapper",
  style: {"max-width":"100%","overflow":"auto"}
}
const _hoisted_2 = { class: "container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppTable = _resolveComponent("AppTable")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_AppTable, {
        "pagination-capture-key": "templates",
        "table-head": _ctx.tableHead,
        "table-body": _ctx.rows,
        "total-rows": _ctx.totalRowsCount,
        "current-page": _ctx.viewConfiguration.page,
        "per-page": _ctx.viewConfiguration.perPage,
        onChangeViewConfiguration: _ctx.changeViewConfiguration,
        onSort: _ctx.changeSorting
      }, null, 8, ["table-head", "table-body", "total-rows", "current-page", "per-page", "onChangeViewConfiguration", "onSort"])
    ])
  ]))
}